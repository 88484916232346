import { useDyteSelector, DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import {
  DyteParticipantTile,
  DyteNameTag,
  DyteAvatar,
  DyteParticipantsAudio,
  DyteAudioVisualizer,
} from '@dytesdk/react-ui-kit';

import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import SetupScreen from './SetupScreen';

import { useGetClassTokenQuery } from 'store/apiSlices/parentDashboard.apiSlice';

import { PARENT_DASHBOARD_ENABLE_AUDIO } from 'configs';
import { useMeeting } from 'hooks';

// interface MeetingData {
//   meetingId?: string;
//   meetingTitle?: string;
//   myStdDetails?: object; // Adjust the type as per your requirement
// }

function ViewerPage({ batchType }: { batchType: string | undefined }) {
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);

  if (!roomJoined) {
    return (
      <>
        <SetupScreen />
      </>
    );
  }
  return (
    <>
      <Participant batchType={batchType} />
    </>
  );
}

function Participant({ batchType }: { batchType: string | undefined }) {
  const { studentId } = useParams();

  const { meeting, students } = useMeeting();

  return students?.map((student) => {
    if (student?.customParticipantId?.split('-').pop() === studentId?.split('-').pop()) {
      return (
        <>
          {batchType === 'Demo' && PARENT_DASHBOARD_ENABLE_AUDIO === 'true' ? (
            <DyteParticipantsAudio meeting={meeting} />
          ) : (
            ''
          )}
          <DyteParticipantTile
            participant={student}
            meeting={meeting}
            key={student.id}
            className='avatar-xl w-full h-[12.4rem] sm:h-[16rem]'
          >
            <DyteAvatar participant={student} size='md' />
            <DyteNameTag participant={student} meeting={meeting}>
              <DyteAudioVisualizer slot='start' participant={student} />
            </DyteNameTag>
          </DyteParticipantTile>
        </>
      );
    }
  });
}

function StreamingStudent({
  classId,
  batchType,
}: {
  classId: number | undefined;
  batchType: string | undefined;
}) {
  const { studentId } = useParams();

  const { data: getClassToken } = useGetClassTokenQuery({
    studentId: Number(studentId),
    classId: Number(classId),
  });
  const authToken = getClassToken?.data?.token;
  // const parentParticipantId = getClassToken?.data?.customParticipantId;

  const [dyteMeeting, initMeeting] = useDyteClient();

  useEffect(() => {
    if (authToken) {
      initMeeting({
        authToken: authToken,
        defaults: {
          audio: false,
          video: false,
        },
      })
        .then(async (meet) => {
          if (!meet) return;
          // if (meet.self.roomJoined) return;
          // await meet.join();
          // const meetings = await meet.connectedMeetings.getConnectedMeetings();
          // let connectedMeetingDataObject: MeetingData = {};
          // for (let i = 0; i < (meetings?.meetings || []).length; i++) {
          //   const meeting = meetings?.meetings[i];
          //   const stdDetails = find(meeting.participants, { customParticipantId: studentId });
          //   if (stdDetails?.customParticipantId) {
          //     connectedMeetingDataObject = {
          //       meetingId: meeting?.id,
          //       meetingTitle: meeting?.title,
          //       myStdDetails: stdDetails,
          //     };
          //     break;
          //   }
          // }

          // if (meet && connectedMeetingDataObject?.meetingId && parentParticipantId) {
          //   if (meet?.meta?.meetingId !== connectedMeetingDataObject?.meetingId) {
          //     const breakoutManager = new BreakoutRoomsManager();
          //     const state = await meet?.connectedMeetings?.getConnectedMeetings(); // missed step

          //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
          //     breakoutManager.updateCurrentState(state as any);

          //     breakoutManager.assignParticipantsToMeeting(
          //       [parentParticipantId],
          //       connectedMeetingDataObject?.meetingId,
          //     );
          //     await breakoutManager.applyChanges(meet);
          //   }
          // }
        })
        .catch((err) => console.log(err, 'error in joining room'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <div className='w-full h-full'>
      <DyteProvider value={dyteMeeting}>
        <ViewerPage batchType={batchType} />
      </DyteProvider>
    </div>
  );
}

export default StreamingStudent;
