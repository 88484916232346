import { useState, useCallback } from 'react';

const useWebcam = ({
  videoRef,
  videoBoxRef,
}: {
  videoRef: React.RefObject<HTMLVideoElement>;
  videoBoxRef: React.RefObject<HTMLDivElement>;
}) => {
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  // Enable the camera
  const enableCam = useCallback(
    async (id: string) => {
      const video = videoRef.current;
      if (!video) return;
      const deviceId = { exact: id };
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId },
        });
        video.srcObject = stream;
        const videoTrack = stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        if (videoBoxRef.current) videoBoxRef.current.style.display = 'block';
        if (settings.height !== undefined && settings.width !== undefined) {
          setOriginalDimensions({ width: settings.width, height: settings.height });
        }
      } catch (err) {
        console.error('Error enabling camera:', err);
      }
    },
    [videoBoxRef, videoRef],
  );

  // Disable the camera
  const disableCam = useCallback(async () => {
    const video = videoRef.current;
    if (!video) return;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      video.srcObject = stream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
    } catch (err) {
      console.error('Error disabling camera:', err);
    }
  }, [videoRef]);

  // Capture the screenshot
  const captureScreenshot = useCallback(async () => {
    const video = videoRef.current;
    if (!video || !originalDimensions) {
      console.error('Video element or dimensions are not set');
      return {
        image: { original: null },
        log: { message: 'Video element or dimensions are not set', state: false },
      };
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      console.error('Failed to get canvas context');
      return {
        image: { original: null },
        log: { message: 'Failed to get canvas context', state: false },
      };
    }
    canvas.width = originalDimensions.width;
    canvas.height = originalDimensions.height;
    context.drawImage(video, 0, 0);
    context.scale(-1, 1);
    context.translate(-originalDimensions.width, 0);
    const screenshotImage = new Image();
    screenshotImage.src = canvas.toDataURL();
    return {
      image: { original: canvas.toDataURL() },
      log: { message: 'Notebook captured successfully', state: true },
    };
  }, [originalDimensions, videoRef]);
  return { videoRef, enableCam, disableCam, captureScreenshot };
};

export default useWebcam;
