import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { useGetPracticeSheetDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { SectionHeadingLogo } from 'assets/svg';
import { BouncingDotsLoader, Footer } from 'components/common';
import { AllPracticeSheets, DashboardNav } from 'components/screens';

import { EMPTY_PRACTICE_PAPER, EVALUATION_SUBMISSION_STATUS, USER_TYPE } from 'configs';
import { IPracticeDoc } from 'types';

import { selectUserType } from 'store/slice/studentRegister.slice';

import CustomTabs from 'components/common/Tabs/CustomTabs';
import PaginationComp from 'components/common/Pagination';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/store';
import { useGetSiblingParentListDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';

export default function Practice() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [allPracticeSheets, setAllPracticeSheets] = useState<IPracticeDoc[]>([]);
  const [allNotSubmittedPracticeSheets, setNotSubmittedAllPracticeSheets] = useState<
    IPracticeDoc[]
  >([]);

  const { studentId } = useParams();

  const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });

  const userType = useAppSelector(selectUserType);

  const { data: practiceSheet, isLoading: isNotSubmittedLoading } = useGetPracticeSheetDetailsQuery(
    {
      filter: {
        studentSubmissionStatus: {
          $in: [
            EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED,
            EVALUATION_SUBMISSION_STATUS.BLANK_SUBMISSION,
            EVALUATION_SUBMISSION_STATUS.WRONG_SUBMISSION,
            EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION,
          ],
        },
      },
      sort: {
        expiresOn: 1,
      },
      pageNumber,
      studentId,
    },
    {
      skip: !profileData?.data?.id && pageNumber > 1,
    },
  );

  const { data: parentSiblingsData } = useGetSiblingParentListDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: userType === USER_TYPE.STUDENT,
  });

  const { data: practiceSheetSubmitted, isLoading: isSubmittedLoading } =
    useGetPracticeSheetDetailsQuery(
      {
        filter: {
          studentSubmissionStatus: {
            $in: [
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
            ],
          },
        },
        sort: {
          submittedOn: 1,
        },
        pageNumber,
        studentId,
      },
      {
        skip: !profileData?.data?.id || pageNumber === 0,
      },
    );

  useEffect(() => {
    setIsPageLoading(isSubmittedLoading);
  }, [isSubmittedLoading]);

  useEffect(() => {
    if (!isSubmittedLoading && practiceSheetSubmitted?.docs) {
      setAllPracticeSheets(practiceSheetSubmitted.docs);
    }
  }, [practiceSheetSubmitted, isSubmittedLoading]);

  useEffect(() => {
    if (!isNotSubmittedLoading && practiceSheet?.docs) {
      setNotSubmittedAllPracticeSheets(practiceSheet.docs);
    }
  }, [isNotSubmittedLoading, practiceSheet?.docs]);

  const sortedPracticeSheets: IPracticeDoc[] = allNotSubmittedPracticeSheets
    ? [...allNotSubmittedPracticeSheets].sort((a, b) =>
        moment(a.expiresOn).isBefore(b.expiresOn) ? -1 : 1,
      )
    : [];

  const handlePageChange = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const [submittedState, setSubmittedState] = useState<boolean>(false);

  return (
    <div className='flex flex-col justify-between min-h-screen bg-gray-100'>
      <DashboardNav type={userType || USER_TYPE?.STUDENT} parentSiblingsData={parentSiblingsData} />
      <div className='flex-1 py-3 mx-4 lg:mx-8 flex flex-col'>
        <h1 className='items-center text-xl font-bold tracking-wider uppercase py-9 md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
          <img src={SectionHeadingLogo} alt='uprio section logo' className='my-4 mr-5 md:my-0' />
          {'Practice Papers'}
        </h1>

        {isSubmittedLoading || isPageLoading || isNotSubmittedLoading ? (
          <div className='flex items-center justify-center flex-1'>
            <BouncingDotsLoader />
          </div>
        ) : (
          <>
            <CustomTabs
              submittedState={submittedState}
              setPageNumber={setPageNumber}
              setSubmittedState={setSubmittedState}
            />
            {isEmpty(practiceSheet?.docs) && isEmpty(practiceSheetSubmitted?.docs) ? (
              <div className='p-10 flex-1 items-center font-semibold text-center text-white bg-primary-700 rounded-2xl'>
                {EMPTY_PRACTICE_PAPER}
              </div>
            ) : (
              <div className='flex-1 flex flex-col justify-between'>
                <AllPracticeSheets
                  docs={!submittedState ? sortedPracticeSheets : allPracticeSheets}
                  isStartPracticePaper={!submittedState ? true : false}
                  isLoading={!submittedState ? isNotSubmittedLoading : isSubmittedLoading}
                  submitted={submittedState}
                  type={!studentId ? USER_TYPE?.STUDENT : USER_TYPE?.GUARDIAN}
                />

                {((!submittedState && practiceSheet?.totalPages !== 0) ||
                  (submittedState && practiceSheetSubmitted?.totalPages !== 0)) && (
                  <PaginationComp
                    currentPage={pageNumber}
                    totalPages={
                      (!submittedState
                        ? practiceSheet?.totalPages
                        : practiceSheetSubmitted?.totalPages) || 1
                    }
                    onPageChange={handlePageChange}
                    showIcons
                    className='text-sm align-middle'
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
