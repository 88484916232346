import { Card } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { filter } from 'lodash';
import { useEffect, useRef } from 'react';
import { useUpdateClassNotesAnaltyicsMutation } from 'store/apiSlices/studentDashboard.apiSlice';
import {
  selectActiveElement,
  selectCurrentPrevIndex,
  selectSetSlidesContent,
  setActiveHTMLSlide,
  setCurrentPrevIndex,
} from 'store/slice/classNotes.slice';
import { ISlidesPreview } from 'types';

export const SlidesPreview = ({
  iframeRef,
  setIsClassContainerCollapsed,
  classId,
}: ISlidesPreview) => {
  const dispatch = useAppDispatch();

  const slidePreviewScrollRefs = useRef<HTMLDivElement | null>(null);

  const [updateClassNotesAnalytics] = useUpdateClassNotesAnaltyicsMutation(); //api for updating slide viewed

  const getActiveElement = useAppSelector(selectActiveElement);
  const getCurrentPrevIndex = useAppSelector(selectCurrentPrevIndex);
  const getSlidesContent = useAppSelector(selectSetSlidesContent);

  const handlePrevImageClick = (slideNo: number) => {
    setIsClassContainerCollapsed(true); //closing classes div
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'uprio_custom',
          fn: 'JumpToAnim',
          params: [0, slideNo, true],
        },
        '*',
      );
    }
    dispatch(setCurrentPrevIndex(slideNo));
    getSlidesContent?.slideData?.forEach((item) => {
      if (item?.id === getActiveElement?.presentationId) {
        dispatch(setActiveHTMLSlide(item?.slideNo?.[slideNo]));
        if (getActiveElement.elementDetails?.elementId) {
          updateClassNotesAnalytics({
            slideNo: slideNo,
            viewed: true,
            classId: classId,
            elementId: getActiveElement.elementDetails?.elementId,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (slidePreviewScrollRefs.current) {
      const selectedSlide = slidePreviewScrollRefs.current.children[getCurrentPrevIndex];
      if (selectedSlide) {
        selectedSlide.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [getCurrentPrevIndex]);

  return (
    <>
      {getActiveElement.type === 'elements' && (
        <Card className='slidesPreview flex gap-2 flex-col w-[12vw] justify-start p-2 shadow-lg'>
          <div
            className='overflow-auto h-[75vh] classNotesScrollbar gap-2 flex flex-col'
            ref={slidePreviewScrollRefs}
          >
            {getActiveElement?.thumbnails?.map((t) => {
              const isSelected = getCurrentPrevIndex === t.slideNo;
              return (
                <div
                  className={`flex flex-col justify-start items-center m-1 p-1 cursor-pointer  shadow-md ${isSelected ? 'border-2 border-primary-500' : 'hover:bg-slate-300'}`}
                  onClick={() => handlePrevImageClick(t.slideNo)}
                >
                  <img src={t.url} />
                  {/* here this filter is to get the slideNo. */}
                  {filter(
                    getSlidesContent.slideData,
                    (item) => getActiveElement?.presentationId === item.id,
                  ).map((item) => (
                    <span key={item.id}>Slide No. {item?.slideNo?.[t.slideNo]}</span>
                  ))}
                </div>
              );
            })}
          </div>
        </Card>
      )}
    </>
  );
};
