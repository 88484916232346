import { useContext, useEffect, useState } from 'react';

import { IWritten } from 'types';
import NotebookQuestion from '../NotebookQuestion';
import { useMeeting, useInclassLogger } from 'hooks';
import { SocketContext } from 'contexts';
import { BouncingDotsLoader } from 'components/common';
import { useAppSelector } from 'hooks/store';
import { selectMyCurrentState } from 'store/slice/inClassConfig.slice';
import { EvaluationData } from 'types/inClass/lecture';
import { selectMultiLecture } from 'store/slice/lecture.slice';
import './styles.css';
import { selectEvalId } from 'store/slice/content.slice';

const Notebook = ({ written, loading }: IWritten) => {
  // State variables
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [writtenQuestionsData, setWrittenQuestionsData] = useState<EvaluationData[] | undefined>(
    [],
  );

  // Redux
  const myCurrentState = useAppSelector(selectMyCurrentState) || 'teach';
  const lectureData = useAppSelector(selectMultiLecture);
  const evalId = useAppSelector(selectEvalId);

  // Hooks
  const { self, studentName, classId } = useMeeting();
  const socket = useContext(SocketContext);
  const { datadogLog } = useInclassLogger();

  useEffect(() => {
    socket?.emit('refreshHuddleState', {
      studentId: self?.customParticipantId,
      classId: classId,
    });
  }, [classId, self?.customParticipantId, socket]);

  useEffect(() => {
    if (written) {
      lectureData?.[myCurrentState]?.map((lecture) => {
        if (lecture.type === 'evaluation' && lecture.evaluationId === evalId) {
          setWrittenQuestionsData(lecture.questions);
        }
      });
    }
  }, [lectureData, evalId, myCurrentState, written]);

  const completeAssessment = () => {
    setIsCompleted(true);

    const participantId = self?.customParticipantId?.split('-').pop();

    if (participantId) {
      const payload = { [participantId]: true };
      socket?.emit('writtenAssessmentCompleted', JSON.stringify(payload));
      datadogLog(`${studentName} informed that they completed the written assessment`);
    } else {
      console.error('Participant ID is undefined. Cannot emit writtenAssessmentCompleted event.');
    }
  };

  if (loading) {
    return (
      <div className='flex flex-col items-center justify-center text-white size-full'>
        <p className='mb-8 text-xl'>Waiting for tutor to start evaluation...</p>
        <BouncingDotsLoader />
      </div>
    );
  }

  return (
    <div className='flex flex-col size-full'>
      <div className='relative overflow-y-scroll text-xl evalScrollbar grow'>
        <div className='sticky top-0 flex justify-center w-full py-5 pl-5 pr-3 text-xl subHeading'>
          <span className='text-2xl font-semibold text-white'>
            Solve these questions in your notebook!
          </span>
        </div>
        {writtenQuestionsData?.map((question) => (
          <NotebookQuestion key={question._id} data={question} />
        ))}
      </div>
      <div className='absolute bottom-0 right-0'>
        <button
          className={`p-3 m-5 text-white bg-green-500 rounded-lg ${isCompleted ? 'bg-green-600' : ''} hover:bg-green-600`}
          onClick={completeAssessment}
          disabled={isCompleted}
        >
          {isCompleted ? 'Tutor Notified' : 'Done'}
        </button>
      </div>
    </div>
  );
};

export default Notebook;
