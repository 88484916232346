import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { reset } from 'store/slice/studentRegister.slice';

import { InputDropdownIcon, InputDropdownIconUp } from 'assets/svg';
import { useAppDispatch } from 'hooks/store';

import { ROUTES, CONFIG } from 'configs';
import { IHandleLogoutProps } from 'types';

const HandleLogout = ({ profileData }: IHandleLogoutProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleLogout = () => {
    Cookie.remove(
      `${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`,
      CONFIG.VITE_BASE_DOMAIN_ENV === 'local'
        ? {
            path: '/',
          }
        : {
            domain: '.uprio.com',
            path: '/',
          },
    );
    // Clear local storage
    localStorage.clear();
    dispatch(reset());
    datadogRum.clearUser();
    // Navigate to the login page
    navigate(ROUTES.LOGIN);
  };

  const handleSwitchAccount = () => {
    navigate(ROUTES.ACCOUNTS, { replace: true });
  };

  // handling the event to close the dropdown if user clicks outside also
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='relative inline-block text-left' ref={dropdownRef}>
      <button
        type='button'
        className='inline-flex justify-center w-full text-sm bg-white border border-gray-300 rounded-3xl text-gray-700m'
        id='options-menu'
        aria-expanded='true'
        aria-haspopup='true'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex items-center'>
          <div className='flex items-center text-white self-center whitespace-nowrap text-base body-semibold bg-neutral-400 py-2 px-3 md:p-1 md:px-3 rounded-full m-[2px]'>
            {profileData?.data?.profileImage ||
              profileData?.data?.firstName.charAt(0) ||
              profileData?.data?.lastName.charAt(0)}
          </div>
          <div className=' hidden md:block px-[6px] font-poppins font-semibold text-neutral-900 text-xs py-[10px]'>
            {profileData?.data?.firstName}
          </div>
          <img
            className='py-2 md:py-[10px] pr-2 md:pr-3'
            src={isOpen ? InputDropdownIconUp : InputDropdownIcon}
            alt='dropdown'
          />
        </div>
      </button>

      {isOpen && (
        <div
          className='origin-top-right absolute w-[10rem] right-0 mt-1 p-1 rounded-md shadow-lg bg-white z-10'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
        >
          <ul>
            <li
              className='p-1 pl-2 text-sm font-medium rounded-md cursor-pointer hover:bg-primary-500 hover:text-white md:p-2 font-poppins'
              onClick={handleSwitchAccount}
            >
              Switch Account
            </li>
            <li
              className='p-1 pl-2 text-sm font-medium rounded-md cursor-pointer hover:bg-primary-500 hover:text-white md:p-2 font-poppins'
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default HandleLogout;
