import React from 'react';
import WaterMark from 'components/common/WaterMark';

interface ContentProps {
  contentUrl: string | undefined;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const Content: React.FC<ContentProps> = ({ contentUrl, iframeRef }) => {
  return (
    <div className='w-[80vw] h-[80vh] md:w-full md:h-[70vh]  max-w-full max-h-full pointer-events-none relative flex justify-center items-center'>
      <WaterMark />
      <iframe
        src={contentUrl + '?userType=student'}
        title='Class Notes'
        allowFullScreen={true}
        className='size-full'
        ref={iframeRef}
      ></iframe>
    </div>
  );
};

export default Content;
