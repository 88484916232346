import classNames from 'classnames';

import { Book } from 'assets/svg';
import { ITopicProps } from 'types';

const Topic = ({ subject, topic, className, inClass }: ITopicProps) => {
  return (
    <div
      className={classNames(`flex items-center gap-2 ${inClass ? '' : 'max-w-[250px]'}`, className)}
    >
      <div className='rounded-full p-2 bg-primary-850 w-[30px] 2xl:w-[35px]'>
        <img src={Book} className='' />
      </div>
      {subject && (
        <>
          <p className='text-lg text-white'>{subject}</p>
          <div className='flex flex-col items-center justify-center mt-1 align-middle'>
            <div className='w-1 h-1 bg-white rounded-full '></div>
          </div>
        </>
      )}
      <p className='text-xs text-white truncate 2xl:text-base'>{topic}</p>
    </div>
  );
};

export default Topic;
