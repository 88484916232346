import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import { DyteNotifications } from '@dytesdk/react-ui-kit';

import { Recording, Topic } from 'components/inClass/Badges';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';

import { InClassUprioLogo } from 'assets/svg';
import NotificationDisplay from 'components/inClass/Notification';
import DyteAudio from 'components/inClass/DyteAudio';

// import { IInClassHeader } from 'types/inClass/header';
// import DyteCustomParticipantsAudio from 'components/inClass/Meeting/DyteCustomParticipantsAudio';

const InClassHeader = ({ tutorActiveRoom }: { tutorActiveRoom: string }) => {
  const { meeting } = useDyteMeeting();
  // Get the auth token from the URL
  const { id } = useParams();
  const authToken = String(id);

  // API Call -> Get the meeting token
  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery({
    authToken,
  });

  const lectureName =
    JoinClassRoomData?.data?.lectureConfigurationName ||
    JoinClassRoomData?.data?.classConfigurationName;

  // Recording
  const recording = useDyteSelector((meeting) => meeting?.recording?.recordings ?? []);
  return (
    <header>
      {/* <DyteParticipantsAudio meeting={meeting} /> */}
      <DyteAudio tutorCurrentState={tutorActiveRoom} />
      <DyteNotifications
        meeting={meeting}
        config={{
          config: {
            notifications: {
              participant_left: false,
              participant_joined: false,
              chat: false,
            },
            notification_sounds: {
              participant_left: false,
              chat: false,
            },
            participant_joined_sound_notification_limit: 10,
            participant_chat_message_sound_notification_limit: 10,
          },
        }}
      />
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          <img src={InClassUprioLogo} alt='logo' />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 1 }}
            className='h-10 ml-3 mr-3 border-l-2 border-white opacity-20'
          ></motion.div>
          {JoinClassRoomData && (
            <>
              {/* Topic  */}
              <Topic
                subject={JoinClassRoomData?.data?.subject}
                topic={lectureName || JoinClassRoomData?.data?.name}
                inClass={true}
              />
            </>
          )}
          {recording.length > 0 && (
            <div className='flex ml-2 transition duration-900 '>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 1 }}
                className='h-10 mr-5 border-l-2 border-white opacity-20'
              ></motion.div>
              {/* Recording Indicator */}
              <Recording />
            </div>
          )}
        </div>
        <NotificationDisplay />
      </div>
    </header>
  );
};

export default InClassHeader;
