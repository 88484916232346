import { ReactNode, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Modal as FlowbiteModal, ModalProps } from 'flowbite-react';
import classNames from 'classnames';

import { Close } from 'assets/svg';

interface IModalProps extends ModalProps {
  children: ReactNode;
  heading?: string;
  showCloseBtn?: boolean;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const backdropVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
};

const modalVariants = {
  hidden: {
    scale: 0.95,
    opacity: 0,
    transition: { duration: 1, ease: 'easeInOut' },
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: { duration: 1, ease: 'easeInOut' },
  },
};

const Modal = ({
  children,
  heading,
  showCloseBtn = true,
  openModal,
  setOpenModal,
  className,
  ...props
}: IModalProps) => {
  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  return (
    <AnimatePresence>
      {openModal && (
        <motion.div
          className='fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50'
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={backdropVariants}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <motion.div
            className='w-full p-2 pb-0 overflow-hidden bg-white rounded-t-lg max-h-half'
            variants={modalVariants}
            initial='hidden'
            animate='visible'
            exit='hidden'
          >
            <FlowbiteModal show={true} onClose={handleClose} {...props}>
              <FlowbiteModal.Body>
                <div
                  className={classNames(
                    `flex pb-3 items-center ${showCloseBtn ? 'justify-between' : 'justify-center'}`,
                    className,
                  )}
                >
                  <div className='text-base font-normal text-gray-900 font-sora sm:text-lg lg:text-xl xl:text-2xl'>
                    {heading}
                  </div>
                  {showCloseBtn && (
                    <button
                      className='p-3 rounded-md hover:bg-gray-200 focus:outline-none'
                      onClick={handleClose}
                    >
                      <img src={Close} alt='Close button' className='w-4 h-4 text-primary-850' />
                    </button>
                  )}
                </div>
                {children}
              </FlowbiteModal.Body>
            </FlowbiteModal>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
