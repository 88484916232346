import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LectureData } from 'types';

interface ILecture {
  lecture: LectureData;
}

const initialState: ILecture = {
  lecture: {},
};

export const multiLecture = createSlice({
  name: 'multiLecture',
  initialState,
  reducers: {
    setMultiLecture: (state, action: PayloadAction<{ lecture: LectureData }>) => {
      const { lecture } = action.payload;
      state.lecture = lecture;
    },
  },
  selectors: {
    selectMultiLecture: (state) => state?.lecture,
  },
});

export const { setMultiLecture } = multiLecture.actions;

export const { selectMultiLecture } = multiLecture.selectors;
