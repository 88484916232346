import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IContentInfo {
  htmlLink: string;
  step: number;
  slide: number;
  evalId: string;
  tutorRoomChange: boolean;
}

const initialState: IContentInfo = {
  htmlLink: '',
  step: 0,
  slide: 1,
  evalId: '',
  tutorRoomChange: false,
};

export const contentInfo = createSlice({
  name: 'contentInfo',
  initialState,
  reducers: {
    setSlideData: (
      state,
      action: PayloadAction<{ step: number; slide: number; htmlLink: string }>,
    ) => {
      const { step, slide, htmlLink } = action.payload;
      state.htmlLink = htmlLink;
      state.step = step;
      state.slide = slide;
    },
    setEvalId: (state, action: PayloadAction<string>) => {
      state.evalId = action.payload;
    },
    setTutorRoomChange: (state, action: PayloadAction<boolean>) => {
      state.tutorRoomChange = action.payload;
    },
  },
  selectors: {
    selectHtmlLink: (state) => state?.htmlLink,
    selectHtmlSlide: (state) => state?.slide,
    selectHtmlStep: (state) => state?.step,
    selectEvalId: (state) => state?.evalId,
    selectTutorRoomChange: (state) => state?.tutorRoomChange,
  },
});

export const { setSlideData, setEvalId, setTutorRoomChange } = contentInfo.actions;

export const {
  selectHtmlLink,
  selectHtmlSlide,
  selectHtmlStep,
  selectEvalId,
  selectTutorRoomChange,
} = contentInfo.selectors;
